/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { uploadToSignedUrl, getUploadSignedUrl, getDownloadSignedUrl } from "helpers/s3";
import FilesActions from "redux-react/actions/filesActions";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Fade, Icon, IconButton, InputAdornment } from "@mui/material";
import { read, utils } from "xlsx";
import { useSelector, useDispatch } from "react-redux";

export default function Step1Upload({ validStep, handleSave }) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const [loadingProgress, setLoadingProgress] = useState(false);

	const [url, setUrl] = useState("");
	const [urlError, setUrlError] = useState("");
	const [loadUrl, setLoadUrl] = useState(false);
	/**
	 * Upload file to S3
	 */
	const uploadFileToS3 = async (file, path) => {
		setLoadingProgress(true);
		// Get upload URL
		let uploadURL = await getUploadSignedUrl(file, path);
		// Upload to S3
		await uploadToSignedUrl(uploadURL, file);
		// Get download URL
		let downloadURLResult = await getDownloadSignedUrl(file, path);

		let accessURL = downloadURLResult?.accessURL;

		if (accessURL && file) {
			return { success: true, path: path, url: accessURL };
		} else {
			return { success: false };
		}
	};
	/**
	 * Handle CSV file
	 */
	const handleCSVFile = async file => {
		const reader = new FileReader();
		return new Promise((resolve, reject) => {
			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				resolve(data);
			};
			reader.onerror = reject;
			reader.readAsArrayBuffer(file);
		});
	};

	/**
	 * Upload file to S3
	 * @param {*} file - File to upload
	 * @returns {Promise<void>} - Promise
	 */
	const uploadFile = async file => {
		const uuid = uuidv4();
		const path = `source/${uuid}`;
		const mimeType = file.type;
		const tabWords = ["sheet", "csv", "excel"];
		const isExcel = tabWords.some(word => mimeType.includes(word));

		// Source metadatas
		const metadatas = {};
		// Default type is file
		let type = "file";
		// Values used for csv
		let values = [];

		if (isExcel) {
			// Case for excel files
			type = "csv";
			values = await handleCSVFile(file);
		}

		// Get cleaned file name => Remove extension
		if (!lod_.isNil(file.name)) {
			let cleanedFileName = file.name.split(".")[0];
			// Set the file name in the metadatas (title and description)
			if (cleanedFileName) {
				metadatas.title = cleanedFileName;
				metadatas.description = cleanedFileName;
			}
		}

		// Upload file to S3
		let uploadResult = await uploadFileToS3(file, path);

		if (!uploadResult.success) {
			setLoadingProgress(false);
			return;
		}

		// File has been uploaded, we can valid the step
		validStep();

		// Save the file
		handleSave({
			type: type,
			file: {
				...file,
				path: uploadResult.path
			},
			url: uploadResult.url,
			values: values,
			metadatas: metadatas
		});
		setLoadingProgress(false);
	};

	/**
	 * Handle upload file
	 */
	const handleUploadFile = async e => {
		setLoadingProgress(true);
		let file = e.target.files[0];
		if (!file) {
			setLoadingProgress(false);
			return;
		}

		await uploadFile(file);
	};

	const downloadFileFromUrl = async () => {
		dispatch(
			FilesActions.getWebsiteInformationsFromUrl(
				profile.assistantID,
				url,
				res => {
					handleSave({
						type: "website",
						file: {
							name: `${res.title}.html`,
							type: "text/html"
						},
						metadatas: {
							title: res.title,
							description: res.description,
							image: res.image,
							url: res.url
						},
						url: res.url
					});
					validStep();
					setLoadingProgress(false);
				},
				err => {
					setLoadingProgress(false);
					setUrlError(i18n.t("SOURCES.UPLOAD.urlError"));
				}
			)
		);
	};

	if (loadingProgress) {
		return (
			<MDBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				style={{ height: "100%" }}
			>
				<CircularProgress color="info" size={80} />
				<MDBox sx={{ mt: 4 }}>
					<MDTypography variant="h6">{i18n.t("SOURCES.UPLOAD.inProgress")}</MDTypography>
				</MDBox>
			</MDBox>
		);
	}

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			style={{ height: "100%", width: "100%" }}
		>
			<MDBox
				flex="1"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="strech"
				style={{ height: "100%", width: "50%", textAlign: "center" }}
			>
				{/* Title */}
				<MDBox mt={1}>
					<MDTypography variant="h4" component="h4">
						{i18n.t("SOURCES.UPLOAD.add")}
					</MDTypography>
				</MDBox>
				{/* Content */}
				<MDBox mt={5}>
					<MDInput
						type="file"
						className="dialogInput"
						onChange={e => {
							handleUploadFile(e);
						}}
					/>
				</MDBox>
				{/* Title */}
				<MDBox mt={1}>
					<MDTypography variant="h6" component="h4">
						{i18n.t("SOURCES.UPLOAD.or")}
					</MDTypography>
				</MDBox>
				{/* Title */}
				<MDBox
					mt={1}
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<MDInput
						disabled={loadUrl}
						value={url}
						className="dialogInput"
						placeholder={i18n.t("SOURCES.UPLOAD.urlPlaceholder")}
						onChange={e => {
							setUrlError("");
							setUrl(e.target.value);
						}}
						onKeyDown={e => {
							if (e.key === "Enter") {
								downloadFileFromUrl();
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{!loadUrl && (
										<IconButton disabled={Boolean(!url)} color="info" onClick={downloadFileFromUrl}>
											<Icon>search</Icon>
										</IconButton>
									)}
									{loadUrl && <CircularProgress color="info" size={20} />}
								</InputAdornment>
							)
						}}
					/>
				</MDBox>
				{urlError && (
					<Fade in={Boolean(urlError)}>
						<MDTypography
							color="error"
							variant="button"
							style={{ marginTop: "0.5rem", textAlign: "center" }}
						>
							{urlError}
						</MDTypography>
					</Fade>
				)}
			</MDBox>
		</MDBox>
	);
}
